<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col md="5">
                    <label class="text-body-2" :for="objective.id + '_externalContent'" >Ressources existantes ou à réaliser</label>
                    <v-textarea
                        :id="objective.id + '_externalContent'"
                        :value="objective.externalContent"
                        @change="updateValue('externalContent', $event)"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                        auto-grow
                        rows="1"
                    ></v-textarea>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_approach'" >Approche pédagogique</label>
                    <v-select
                        :id="objective.id + '_approach'"
                        :value="objective.approach"
                        @change="updateValue('approach', $event)"
                        :items="approachList"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                    ></v-select>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_method'" >Modalités</label>
                    <v-select
                        :id="objective.id + '_method'"
                        :value="objective.method"
                        @change="updateValue('method', $event)"
                        :items="methodList"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                    ></v-select>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_productionDuration'">Temps de production</label>
                    <v-text-field
                        :id="objective.id + '_productionDuration'"
                        :value="objective.productionDuration"
                        @change="updateValue('productionDuration', $event)"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                        type="number"
                        max="100"
                        min="0"
                        step="0.5"
                        suffix="jours"
                    ></v-text-field>
                </v-col>
                <v-col md="1">
                    <label class="text-body-2" :for="objective.id + '_classDuration'" >Durée (min)</label>
                    <v-text-field
                        :id="objective.id + '_classDuration'"
                        :value="objective.classDuration"
                        @change="updateValue('classDuration', $event)"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                        type="number"
                        max="9999"
                        min="0"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="5">
                    <label class="text-body-2" :for="objective.id + '_learnerActivities'" >Activités apprenant</label>
                    <v-textarea
                        :id="objective.id + '_learnerActivities'"
                        :value="objective.learnerActivities"
                        @change="updateValue('learnerActivities', $event)"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                        auto-grow
                        rows="1"
                    ></v-textarea>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_learnerApproach'" >Situation</label>
                    <v-select
                        :id="objective.id + '_learnerApproach'"
                        :value="objective.learnerApproach"
                        @change="updateValue('learnerApproach', $event)"
                        :items="learnerApprochList"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                    ></v-select>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_externalRessources'" >Ressources nécessaires</label>
                    <v-select
                        :id="objective.id + '_externalRessources'"
                        :value="objective.externalRessources"
                        @change="updateValue('externalRessources', $event)"
                        :items="ressourceList"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                    ></v-select>
                </v-col>
                <v-col md="2">
                    <label class="text-body-2" :for="objective.id + '_tools'" >Outils nécessaires</label>
                    <v-select
                        :id="objective.id + '_tools'"
                        :value="objective.tools"
                        @change="updateValue('tools', $event)"
                        :items="toolList"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                    ></v-select>
                </v-col>
                <v-col md="1">
                    <label class="text-body-2" :for="objective.id + '_learnerDuration'" >Durée (min)</label>
                    <v-text-field
                        :id="objective.id + '_learnerDuration'"
                        :value="objective.learnerDuration"
                        @change="updateValue('learnerDuration', $event)"
                        outlined
                        class="lightborder"
                        background-color="white"
                        color="darkblue"
                        min="0"
                        max="9999"
                        type="number"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-expansion-panels flat class="mb-16">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey" class="text-body-1 font-weight-bold py-0 tutoringPanel"><v-icon class="v-expansion-panel-header__icon ml-0 mr-4">fas fa-edit</v-icon>Accompagnement</v-expansion-panel-header>
                        <v-expansion-panel-content color="background">
                            <v-row class="mt-3">
                                <v-col md="6">
                                    <label class="text-body-2" :for="objective.TutoringTask.id + '_goal'" >Objectifs de l'intervention</label>
                                    <v-select
                                        :id="objective.TutoringTask.id + '_goal'"
                                        :value="objective.TutoringTask.goal"
                                        @change="setInterventionInfo($event)"
                                        :items="interventionGoalsList"
                                        outlined
                                        class="lightborder"
                                        background-color="white"
                                        color="darkblue"
                                    ></v-select>
                                </v-col>
                                <v-col md="5">
                                    <label class="text-body-2" :for="objective.TutoringTask.id + '_answer'" >Réponses suggérées</label>
                                    <v-textarea
                                        :id="objective.TutoringTask.id + '_answer'"
                                        :value="objective.TutoringTask.answer"
                                        @change="updateValue('TutoringTask.answer', $event)"
                                        outlined
                                        class="lightborder"
                                        background-color="white"
                                        color="darkblue"
                                        auto-grow
                                        rows="1"
                                        readonly
                                    ></v-textarea>
                                </v-col>
                                <v-col md="1">
                                    <label class="text-body-2" :for="objective.TutoringTask.id + '_duration'" >Durée (min)</label>
                                    <v-text-field
                                        :id="objective.TutoringTask.id + '_duration'"
                                        :value="objective.TutoringTask.duration"
                                        @change="updateValue('TutoringTask.duration', $event)"
                                        outlined
                                        class="lightborder"
                                        color="darkblue"
                                        type="number"
                                        min="0"
                                        max="9999"
                                        background-color="white"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <label class="text-body-2" :for="objective.TutoringTask.id + '_modalities'" >Modalités</label>
                                    <v-select
                                        :id="objective.TutoringTask.id + '_modalities'"
                                        :value="objective.TutoringTask.modalities"
                                        @change="updateValue('TutoringTask.modalities', $event)"
                                        :items="tutoringMethodList"
                                        outlined
                                        class="lightborder"
                                        background-color="white"
                                        color="darkblue"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <label class="text-body-2" :for="objective.TutoringTask.id + '_externalContent'" >Fréquence</label>
                                    <v-select
                                        :id="objective.TutoringTask.id + '_externalContent'"
                                        :value="objective.TutoringTask.position"
                                        @change="updateValue('TutoringTask.position', $event)"
                                        :items="frequenceList"
                                        outlined
                                        class="lightborder"
                                        background-color="white"
                                        color="darkblue"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "SpeObjectiveModal",

    props: {
        // TODO: objective from store
        objective: Object,
        isDialog: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({}),

    computed: {
        ...mapState("global", [
            "bloomList",
            "approachList",
            "learnerApprochList",
            "methodList",
            "modalityList",
            "toolList",
            "ressourceList",
            "frequenceList",
            "interventionGoals",
            "interventionGoalsList",
            "tutoringMethodList",
        ]),

        ...mapState("formations", ["currentFormation"])
    },

    methods: {
        ...mapActions("formations", ["modifySpecificObjective", "modifyTutoringTask", "getSpecificObjectives", "setCurrentFormation"]),

        setInterventionInfo: async function(value) {
            const goal = this.interventionGoals.find((obj) => obj.name === value);

            let answer
            try {
                answer = goal.answer.join("\n");
            } catch {
                answer = goal.answer;
            }

            await this.modifyTutoringTask({
                id: this.objective.TutoringTask.id,
                goal: value,
                supportPlan: goal.tutoringSupportPlan,
                function: goal.tutoringFunction,
                answer: answer,
            });

            await this.getSpecificObjectives(this.objective.formationId);
        },

        updateValue: async function(element, value) {
            const splitedElement = element.split(".")

            if(splitedElement[0] === "TutoringTask") {
                await this.modifyTutoringTask({
                    id: this.objective.TutoringTask.id,
                    [splitedElement[1]]: value,
                });
            } else {

                const onlineMethods = ["Classe virtuelle", "Atelier en ligne", "Vidéo en ligne"];

                if(element === "method" && onlineMethods.includes(value)) {
                    await this.modifySpecificObjective({
                        id: this.objective.id,
                        method: value,
                        learnerApproach: "A distance",
                    });
                } else {
                    await this.modifySpecificObjective({
                        id: this.objective.id,
                        [element]: value,
                    });
                }
            }

            if(element === "classDuration" || element === "learnerDuration" || element === "productionDuration" || splitedElement[1] === "duration") {
                await this.setCurrentFormation(this.currentFormation);
            }
        },
    },
};
</script>

<style scoped>
.tutoringPanel {
    min-height: 32px !important;
}
</style>
