<template>
    <v-container fluid>
        <page-banner links="Ma formation/Scripts"></page-banner>

        <formation-header></formation-header>
        <span class="float-right"><v-icon class="mr-3" title="Temps de production">fas fa-wrench</v-icon>{{ productionDuration }} jour(s)</span>

        <v-row class="mt-10">
            <v-expansion-panels flat tile accordion>
                <v-expansion-panel v-for="eduObj in educationalObjectives" :key="eduObj.id" class="mb-2" >
                    <v-expansion-panel-header class="pink py-0">
                        <v-row no-gutters class="subtitle-1">
                            <v-col cols="7">
                                <v-icon class="mr-5">fas fa-sort-amount-down-alt</v-icon>
                                {{ eduObj.name }}
                            </v-col>
                            <v-col offset-md="3" cols="1">
                                <v-icon> fas fa-hands-helping</v-icon>
                                {{ eduObj.tutoringDuration | durationToHuman }}
                            </v-col>
                            <v-col cols="1">
                                <v-icon> fas fa-user-graduate</v-icon>
                                {{ eduObj.learnerDuration | durationToHuman }}
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="background">
                        <v-container fluid class="pt-8 px-0">
                            <v-row>
                                <v-col class="">
                                    <custom-input
                                        type="educational"
                                        :object-id="eduObj.id"
                                        value-name="competence"
                                        label="Compétence à développer"
                                        long-input
                                    ></custom-input>
                                </v-col>
                                <v-col class="">
                                    <custom-input
                                        type="educational"
                                        :object-id="eduObj.id"
                                        value-name="evaluationMethod"
                                        label="Modalités d'évaluation"
                                        long-input
                                    ></custom-input>
                                </v-col>
                                <v-col class="">
                                    <custom-input
                                        type="educational"
                                        :object-id="eduObj.id"
                                        value-name="evaluationCriteria"
                                        label="Critères d'évaluation"
                                        long-input
                                    ></custom-input>
                                </v-col>
                            </v-row>

                            <v-divider class="my-10"></v-divider>

                            <v-row v-for="opeObj in getOperationalObjectivesByParentId(eduObj.id)" :key="opeObj.id">
                                <v-col>
                                    <v-row>
                                        <span><v-icon color="purple" class="mr-4">fas fa-bars</v-icon> {{ opeObj.name }}</span>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-row v-for="speObj in getSpecificObjectivesByParentId(opeObj.id)" :key="speObj.id">
                                                <v-col>
                                                    <v-row>
                                                        <span class="ml-10 mt-5"><v-icon color="purple">fas fa-grip-lines</v-icon> {{ speObj.name }}</span>
                                                    </v-row>
                                                    <spe-objective-modal :objective="speObj"></spe-objective-modal>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>

        <next-page-button></next-page-button>
    </v-container>
</template>

<script>
import SpeObjectiveModal from "@/components/designer/SpeObjectiveModal";
import PageBanner from "@/components/PageBanner";
import FormationHeader from "@/components/designer/FormationHeader";

import { mapActions, mapGetters, mapState } from "vuex";
import CustomInput from "@/components/CustomInput";
import NextPageButton from "../../../components/NextPageButton";

export default {
    name: "Script",
    components: {
        NextPageButton,
        SpeObjectiveModal,
        CustomInput,
        PageBanner,
        FormationHeader,
    },

    created() {
        this.getOperationalObjectives(this.currentFormation)
        this.getSpecificObjectives(this.currentFormation);
    },

    computed: {
        ...mapState("formations", ["currentFormation", "educationalObjectives", "operationalObjectives", "specificObjectives"]),

        ...mapGetters("formations", ["getOperationalObjectivesByParentId", "getSpecificObjectivesByParentId"]),

        objectiveId() {
            return Number(this.$route.params.id);
        },

        productionDuration() {
            let time = 0;

            for(const obj of this.educationalObjectives) {
                time += obj.productionDuration;
            }

            return time
        }
    },

    methods: {
        ...mapActions("formations", ["modifyEducationalObjective", "setCurrentFormation", "getSpecificObjectives", "getOperationalObjectives"]),

        modifyEducational(event, id, element) {
            this.modifyEducationalObjective({
                id: id,
                [element]: event.target.value,
            });
        },
    },
};
</script>

<style scoped>

</style>